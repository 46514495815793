import React from "react";
import ClassSearch from "app/components/Algolia/ClassSearch";
import { useParams } from "react-router";
import { useFamilyFriendlyFlag } from "hooks/useFeatureFlags";
import { useResetInitialPagination } from "hooks/useResetInitialPagination";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { CategoriesBodyWrapper } from "../CategoriesBodyWrapper";

interface ParamTypes {
  categorySlug: string;
}

export default () => {
  const { categorySlug } = useParams<ParamTypes>();
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();
  const { resettingPagination } = useResetInitialPagination();

  if (resettingPagination) {
    return <LoaderCentered />;
  }

  return (
    <CategoriesBodyWrapper>
      <ClassSearch
        isFamilyFeatureEnabled={isFamilyFeatureEnabled}
        refinements={{
          categories: categorySlug,
        }}
      />
    </CategoriesBodyWrapper>
  );
};
